<template>

	<div id="box-help" :class="{ open : opened }">

		<div class="close" @click="$parent.help_opened = false"></div>

		<div class="content"  @click="$parent.help_opened = false">

			<div>
				<h3>Versus IDX</h3>
				<p>
					<strong>Moy. VS</strong> - <strong>Moy. saison</strong>
				</p>
			</div>

			<div>
				<h3>Versus -/+</h3>
				<p>
					<em>Nombre de points encaissés par la team adversaire comparé à la moyenne de la ligue.</em>
				</p>
			</div>

			<div>
				<h3>MOM - Momentum</h3>
				<p>( ( <strong>Moy. 30 derniers jours</strong> + <strong>Moy. 10 derniers jours</strong> x 2 ) - <strong>Moy. saison</strong> ) / 3</p>
			</div>

			<div>
				<h3>STAB - Stabilité</h3>
				<p>Écart type</p>
			</div>

			<div>
				<h3>H/A - Home/away</h3>
				<p><strong>Moy. home (ou away)</strong> - <strong>Moy. saison</strong></p>
			</div>

			<div>

				<h3>EST - Estimation</h3>

				<p>
					<strong>Moy. saison</strong> + ( ( <strong>MOM</strong> x2 + <strong>VS</strong> + <strong>DEF</strong> + <strong>H/A</strong> + <strong>B2B</strong> ) / 6 )<br>
					<em>Estimation du score selon les différents indicateurs.</em>
				</p>

			</div>

			<div>

				<h3>NF - Nez fin</h3>

				<p>
					( <strong>VS</strong> + <strong>"-/+"</strong> + <strong>MOM</strong> + <strong>H/A</strong> ) / 5<br>
					<em>Compilation de tous les indicateurs en un seul.</em>
				</p>

			</div>

		</div>

	</div>
	
</template>


<script>

	import device from '@/mixins/device'

	export default {

		name	: 'box-help',

		props	: [ 'opened' ],

		components	: {
		},

		data	: function() { return {
		}},

		mixins	: [ device ],

		computed : {
		},

		watch : {
		},

		mounted	: function() {
		}

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#box-help {

		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: 999;
		text-transform: uppercase;
		opacity: 0;
		pointer-events: none;
		transition: opacity 300ms linear;

		&.open {
			opacity: 1;
			pointer-events: auto;
		}

		& > div.content {

			position: relative;
			width: 80vw;
			padding: 25px;
			background-color: #FFF;
			border: 4px solid #333;
			z-index: 2;

			@include mobile() {
				width: 100vw;
				height: 100vh;
				padding: 15px;
				overflow-y: auto;
			}
			
		}

		& > div.close {

			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 1;
			background-color: rgba( 0, 0, 0, 0.6 );
			opacity: 1;

			// @include mobile() {
			// 	z-index: 3;
			// 	background-color: transparent;
			// }

		}

		& > div.content > div {

			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
			border-bottom: 2px solid #000;

			@include mobile() {
				flex-flow: row wrap;
			}

			&:last-child {
				border-bottom: 0;
			}

		}

		h3, p {

			display: inline-block;
			flex: auto;
			margin: 0;
			text-align: left;

			@include mobile() {
				display: block;
				flex: 100%;
				max-width: 100%;
			}

		}

		h3 {

			flex: 20%;
			max-width: 20%;
			margin-right: 25px;
			font-weight: bold;
			font-size: 1.3em;

			@include mobile() {
				flex: 100%;
				max-width: 100%;
				margin-block: 10px;
				font-size: 1.1em;
			}
		}

		p {
			font-family: Sans-serif;
			font: 0.8em;
			line-height: 1.3em;
		}

	}

</style>